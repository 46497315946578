import { allModulesWhitelist } from "@helpers/schemas"
import { PublicationDetail, PublicationDetail2x } from "@thumbnails/Templates"

// Defaults from modules
import SocialShare from "@schemas/modules/SocialShare"

const twoDigits = num => {
  return `${num < 10 ? "0" : ""}${num}`
}

const today = () => {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}/${twoDigits(month)}/${twoDigits(day)}`
}

export default {
  schemaType: "template",
  displayName: "Publication Detail",
  component: "PublicationDetail",
  dataPacks: ["PUBLICATIONS"],
  type: {
    label: "Publication Detail",
    value: "publicationdetail",
    mode: "detail",
  },
  content: [
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      mandatory: true,
      note: "Go to the Category section (Global or Site) to create as many as you need. Publication type and Academic area shown on detail and the distributor card",
      elements: [
        {
          key: "publicationType",
          placeholder: "Publication type",
          source: "PUBLICATION_TYPE",
        },
        {
          key: "academicArea",
          placeholder: "Academic area",
          source: "ACADEMIC_AREA",
        },
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "schools",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          placeholder: "Programs",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          placeholder: "Subject Area & Values",
          source: "SUBJECT_AREA",
        },
        {
          key: "centers",
          placeholder: "Centers & Departments",
          source: "CENTERS",
        },
        {
          key: "topics",
          placeholder: "Topic",
          source: "TOPIC",
        },
      ],
    },
    {
      key: "publicationData",
      title: "Publication Data",
      type: "FieldGroup",
      fields: [
        {
          title: "Image",
          key: "image",
          type: "ImageField",
          hideable: true,
          helptext: "Shown on the distributor card",
        },
        {
          title: "Date",
          type: "DateField",
          key: "publicationDate",
          hideable: true,
        },
        {
          title: "Author(s)",
          type: "TextField",
          key: "author",
          hideable: true,
        },
        {
          title: "Organization",
          type: "TextField",
          key: "organization",
          hideable: true,
        },
        {
          title: "ISBN",
          type: "TextField",
          key: "ISBN",
          hideable: true,
        },
        {
          key: "publication",
          title: "Publication",
          type: "ComponentContainer",
          whiteList: ["Link"],
          hideable: true,
        },
      ],
    },
    {
      key: "mainContent",
      title: "Main Content",
      type: "FieldGroup",
      fields: [
        {
          title: "Lead",
          type: "TextField",
          key: "lead",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Content",
          type: "RichText",
          html: true,
          key: "content",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Citation",
          type: "RichText",
          html: true,
          key: "citation",
          hideable: true,
          humanReadable: true,
        },
        {
          key: "languageCard",
          title: "Language",
          type: "TextField",
          hideable: true,
          helptext: "Shown on the distributor card",
          humanReadable: true,
        },
        {
          key: "description",
          title: "Abstract for distributor",
          type: "RichText",
          html: true,
          mandatory: true,
          helptext: "Shown on the distributor card",
          humanReadable: true,
        },
        {
          key: "auxInfo",
          title: "Auxiliar Information",
          type: "TextField",
          hideable: true,
          helptext: "Shown on the distributor card",
          humanReadable: true,
        },
      ],
    },
    {
      key: "relatedContent",
      title: "Related Content Container",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    templateType: "PublicationDetail",
    breadCrumb: [],
    categories: [],
    image: "",
    publicationDate: today(),
    author: "Lorem Ipsum",
    organization: "Lorem Ipsum",
    ISBN: "0000000000000",
    publication: {
      component: "Link",
      text: "Loremipsum.com",
      style: "link",
      url: {
        newTab: true,
        noFollow: true,
        internal: true,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    lead: "Lorem ipsum",
    content: "Lorem ipsum",
    citation: "Lorem ipsum",
    languageCard: null,
    description: "Lorem ipsum",
    auxInfo: "Lorem ipsum",
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [{ ...SocialShare.default }],
      sectionPosition: 4,
    },
  },
  thumbnails: {
    "1x": PublicationDetail,
    "2x": PublicationDetail2x,
  },
}
